import { findTarget, handleESC, importer, FocusHandler } from '../utils';

const translation = (() => {
  const config = {
    triggers: [...document.querySelectorAll('.js-translationTrigger')],
    targetCN: 'js-translationTarget',
    parentCN: 'js-translationParent',
    dismissCN: 'js-translationDismiss',
    focusHandler: false,
  };

  const handleTab = event => {
    if (event.key === 'Tab') {
      // keep in the focus within
      config.focusHandler.maintainFocus(event);
    }
  };

  const handleDismiss = ({ target }) => {
    target.classList.remove('-show');
    // accessibility
    target.setAttribute('aria-hidden', 'true');
    // focus last element (translate trigger)
    config.focusHandler.focusLastActiveElement();
    // stop handling tab within
    document.removeEventListener('keydown', handleTab);
    // stop listening ESC pressing
    handleESC.stop();
  };

  const handleClick = ({ e, target }) => {
    e.preventDefault();
    // show the target
    target.classList.add('-show');
    // accessibility
    target.removeAttribute('aria-hidden');
    // focus on first focusable element within lightbox
    config.focusHandler.focusFirstFocusable();
    // maintain focus within lightbox
    document.addEventListener('keydown', handleTab);
    // handle ESC button
    handleESC.init(() => handleDismiss({ target }));
  };

  const build = () => {
    // import the script from google translate
    importer
      .url('https://translate.google.com/translate_a/element.js')
      .then(() => {
        // let's have an interval to wait for google to be ready
        // we'll have a count and a tolken to know when it's ready
        let count = 0;
        const isGoogleReady = setInterval(() => {
          // try to start the google select
          try {
            // this can throw an error when the google object is not ready
            // eslint-disable-next-line no-undef, no-new
            new google.translate.TranslateElement(
              { pageLanguage: 'sv', autoDisplay: false },
              'google_translate_element',
            );
            // add rel noopener attributes to google map link
            document
              .querySelector('#google_translate_element a[target]')
              .setAttribute('rel', 'noopener');

            clearInterval(isGoogleReady);
          } catch (e) {
            count += 1;

            if (count > 60) {
              clearInterval(isGoogleReady);
            }
          }
        }, 200);

        // Google translate top bar adds a top value to the <body> style
        // it disrupts the layout (especially accessibility)
        // let's remove it: we'll observe when the [style] on the body changes
        // Edge has some trouble with MutationObserver (it freezes)
        // so we'll exclude it
        if ('MutationObserver' in window && !/Edge/.test(navigator.userAgent)) {
          const observer = new MutationObserver(mutations => {
            mutations.forEach(() => {
              document.body.style.top = null;
            });
          });
          observer.observe(document.body, {
            attributes: true,
            attributeFilter: ['style'],
          });
        }
      });

    config.triggers.forEach(trigger => {
      // get the modal
      const target = findTarget({
        element: trigger,
        parent: config.parentCN,
        target: config.targetCN,
      });
      // get the closing button (X)
      const dismiss = findTarget({
        element: trigger,
        parent: config.parentCN,
        target: config.dismissCN,
      });
      // attach the handlers
      trigger.addEventListener('click', e => handleClick({ e, target }));
      dismiss.addEventListener('click', () => handleDismiss({ target }));

      // at the moment FocusHandler is only allowed for one
      config.focusHandler = new FocusHandler(target);
    });
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

export default translation.init();
