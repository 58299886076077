const imgLoader = (() => {
  const config = {
    triggers: [...document.querySelectorAll('.js-imgLoadTrigger')],
    triggerCN: 'js-imgLoadTrigger',
    imgLoadedCN: 'loaded',
  };

  const handleImg = (trigger, i) => {
    const imgContainer = trigger;
    const altText = imgContainer.dataset.alt;
    // create new image
    const img = new Image();
    // remove trigger classname in case the function is called later
    imgContainer.classList.remove(config.triggerCN);

    // add source to newly-created image
    img.src = imgContainer.dataset.href;
    // add accessiblity attribute
    img.role = 'presentation';

    // IE has some issues with Object fit
    // let's detect if browser is IE and add the image as background-image instead
    // IMHO this should done in the markup to avoid using JS unnecessarily
    if (
      navigator.userAgent.indexOf('MSIE') > -1 ||
      navigator.appVersion.indexOf('Trident/') > -1
    ) {
      // once img src is load perform the following
      img.onload = () => {
        imgContainer.classList.add('-ie');
        // add img as background-image
        imgContainer.style.backgroundImage = `url(${img.src})`;
      };
    } else {
      // all other browsers except IE
      // once img src is load perform the following
      img.onload = () => {
        // add classname when loaded
        img.classList.add(config.imgLoadedCN);
        // add FadeIn delay
        img.style.transitionDelay = `${100 * i}s`;
        // add img to DOM
        imgContainer.appendChild(img);
      };

      // add alternative text if it exsits
      if (altText) {
        img.alt = altText;
      }
    }
  };

  const build = () => {
    config.triggers.forEach((trigger, i) => handleImg(trigger, i));
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  const reset = () => {
    // get new triggers in DOM
    config.triggers = [...document.querySelectorAll(`.${config.triggerCN}`)];
    // run script again
    init();
  };

  return {
    init,
    reset,
  };
})();

imgLoader.init();

export default imgLoader;
