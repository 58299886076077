import { findTarget, handleESC, freeze, FocusHandler } from '../utils';

const lightbox = (() => {
  const config = {
    triggers: [...document.querySelectorAll('.js-lightboxTrigger')],
    parentCN: 'js-lightboxParent',
    dismissCN: 'js-lightboxDismiss',
    targetCN: 'js-lightboxTarget',
    showCN: '-show',
    focusHandler: false,
  };

  const handleTab = event => {
    if (event.key === 'Tab') {
      // keep in the focus within
      config.focusHandler.maintainFocus(event);
    }
  };

  const hide = target => {
    // hide lightbox
    target.classList.remove(config.showCN);
    // accessibility
    target.setAttribute('aria-hidden', true);
    // unfreeze HTML
    freeze.stop();
    // stop listening to ESC
    handleESC.stop();
    // focus last element (button trigger)
    config.focusHandler.focusLastActiveElement();
    // stop handling tab within
    document.removeEventListener('keydown', handleTab);
  };

  const show = target => {
    // show lightbox
    target.classList.add(config.showCN);
    // accessibility
    target.removeAttribute('aria-hidden');
    // freeze HTML so it's no possible to scroll
    freeze.start();
    // listen for ESC button
    handleESC.init(() => hide(target));
    // focus on first focusable element within lightbox
    config.focusHandler.focusFirstFocusable();
    // maintain focus within lightbox
    document.addEventListener('keydown', handleTab);
  };

  const build = () => {
    config.triggers.forEach(trigger => {
      const target = findTarget({
        element: trigger,
        parent: config.parentCN,
        target: config.targetCN,
      });

      const dismiss = findTarget({
        element: trigger,
        parent: config.parentCN,
        target: config.dismissCN,
      });

      if (target) {
        trigger.addEventListener('click', () => show(target));
        if (dismiss) {
          dismiss.addEventListener('click', () => hide(target));
        }
        // at the moment FocusHandler is only allowed for one
        config.focusHandler = new FocusHandler(target);
      }
    });
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

export default lightbox.init();
