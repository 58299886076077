export default function findParent(element, parent) {
  let nodeList = element.parentNode;
  let a = 0;

  while (nodeList) {
    if (nodeList === document) {
      break;
    }
    if (nodeList.classList.contains(parent)) {
      return nodeList;
    }
    a += a;
    nodeList = nodeList.parentNode;
  }

  return document;
}
