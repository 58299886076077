export default (() => {
  const stop = (callback = false) => {
    window.onkeyup = null;

    if (callback !== false) {
      callback();
    }
  };

  const init = (callback = false) => {
    window.onkeyup = e => {
      switch (
        e.which // ESC
      ) {
        case 27:
          stop();
          if (callback !== false) {
            callback();
          }
          break;
        default:
          break;
      }
    };
  };

  return {
    init,
    stop,
  };
})();
