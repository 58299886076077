// add required core-js polyfills
import 'js-polyfills/dom';
import 'js-polyfills/html';
import 'core-js';
import 'classlist-polyfill';
import 'svgxuse';

import './components/img-loader';
import './components/news';
import './components/input';
import './components/form-contact';
import './components/carousel';
import './components/lightbox';
import './components/translation';
import './components/menu';
import './components/object-fit';

require('es6-promise').polyfill();
