import newsArchive from '../api/news-archive';
import imgLoader from './img-loader';
import { translationGuard, shorten, findTarget, dataExist } from '../utils';

const news = (() => {
  const config = {
    triggers: [...document.querySelectorAll('.js-newsTrigger')],
    targetCN: 'js-newsTarget',
    accessiblityHelper: 'js-accessiblityHelperNews',
  };

  const createMarkup = posts =>
    posts
      .map(
        (
          post,
          i,
        ) => `<li class="grid__column tileLink__container -tablet-6 -desktop-4">
          <a
            href="${post.link}"
            class="tileLink"
            ${i === 0 ? `id="${config.accessiblityHelper}"` : ''}
          >
          ${
            dataExist(post, 'title')
              ? `<h1 class="tile__title">${translationGuard(post.title)}</h1>`
              : ''
          }
          ${
            dataExist(post, 'preamble') && !post.showImage
              ? `
                <div class="tile__content">
                  ${translationGuard(shorten(post.preamble, 130))}
                </div>
                `
              : ''
          }
          ${
            dataExist(post, 'formattedDate')
              ? `<time class="tile__time" ${
                  dataExist(post, 'date') ? `datetime="${post.date}"` : ''
                }>${post.formattedDate}</time>`
              : ''
          }
          ${
            post.showImage
              ? `
                <div class="tile__imgContainer">
                  <div class="tile__img imgLoader js-imgLoadTrigger imgLoader__placeholder" data-href="${
                    post.image.link
                  }" data-alt="${post.image.alt}"></div>
                </div>`
              : ''
          }
        </a>
      </li>`,
      )
      .join('');

  const handleAccessiblity = () => {
    // focus on first link of the newly-inserted news
    const linkToFocusOn = document.querySelector(
      `#${config.accessiblityHelper}`,
    );
    if (linkToFocusOn) {
      linkToFocusOn.focus();
      // remove the id to be able to perform the action later again
      linkToFocusOn.removeAttribute('id');
    }
  };

  const handleResponse = ({ data, trigger }) => {
    const target = findTarget({ element: trigger, target: config.targetCN });

    if (data.hasNext) {
      // there's still content to fetch so show button
      trigger.removeAttribute('disabled');
      trigger.classList.remove('-disabled');
    } else {
      // delete button as there's nothing else to display
      trigger.parentNode.parentNode.removeChild(trigger.parentNode);
    }
    // add new items to DOM
    target.insertAdjacentHTML('beforeend', createMarkup(data.posts));
    // focus on first link of the newly-inserted news
    handleAccessiblity();
    // fetch images
    imgLoader.reset();
  };

  const getNextPage = trigger => {
    const { dataset } = trigger;
    // get current page
    const currentPage = parseFloat(dataset.page);
    // calculate next page
    const nextPage = currentPage + 1;
    // store next page in DOM
    dataset.page = nextPage;

    return nextPage;
  };

  const handleClick = ({ e, trigger }) => {
    // get the next page index
    const nextPage = getNextPage(trigger);

    e.preventDefault();

    // disable button to prevent multiple requests
    trigger.setAttribute('disabled', 'disabled');
    trigger.classList.add('-disabled');

    newsArchive.get(`?page=${nextPage}`).then(response => {
      if (response.status === 200) {
        handleResponse({ data: response.data, trigger });
      }
    });
  };

  const build = () => {
    config.triggers.forEach(trigger => {
      trigger.addEventListener('click', e => handleClick({ e, trigger }));
    });
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

export default news.init();
