import {
  findTarget,
  findParent,
  freeze,
  handleESC,
  FocusHandler,
  viewport,
  events,
} from '../utils';

const menu = (() => {
  const config = {
    listItem: [...document.querySelectorAll('.js-menuMobileListItem')],
    listItemCN: 'js-menuMobileListItem',
    linkCN: 'js-menuMobileLink', // this can be a <a> or a <div>
    navs: [...document.querySelectorAll('.js-menuMobileSubNav')],
    navCN: 'js-menuMobileSubNav',
    subMenuTriggerButton: '.js-menuSubMenuTrigger',
    activeCN: '-active',
    hideCN: '-hide',
    showCN: '-show',
    hasChildrenCN: '-has-children',
    headingCN: '-heading',
    checkbox: document.querySelector('.js-menuEnabler'),
    hamburger: document.querySelector('.js-menuTrigger'),
    target: document.querySelector('.js-menuTarget'),
    dismiss: document.querySelector('.js-menuDismiss'),
    back: document.querySelector('.js-menuBack'),
    menuDesktop: document.querySelector('.js-menuDesktop'),
    focusHandler: [],
  };

  const back = {
    show() {
      // remove aria attribute
      config.back.removeAttribute('aria-hidden');
      // show button
      config.back.classList.add(config.showCN);
      // accessibility: focus on the back button
      config.back.focus();
    },
    hide() {
      config.back.setAttribute('aria-hidden', true);
      config.back.classList.remove(config.showCN);
      // accessibility: focus on the X (close) button
      config.dismiss.focus();
    },
  };

  const link = {
    state(s, element) {
      let heading;
      switch (s) {
        case 'heading':
          // add classname
          element.classList.add(config.headingCN);
          break;
        case 'reset':
          // get all links with heading classname
          heading = [...config.target.querySelectorAll(`.${config.headingCN}`)];
          if (heading.length > 0) {
            // remove the classname
            heading.forEach(h => h.classList.remove(config.headingCN));
          }
          break;

        default:
          break;
      }
    },
    current() {
      return config.target.querySelector(`.${config.activeCN}`);
    },
  };

  const level = {
    current() {
      const obj = {};
      // get current link
      obj.activeLink = link.current();
      /* Get the level of the active link
       * if there's no link in the menu (could happen as the back-end structure can be improved)
       * then set level to 1
       */
      obj.current = obj.activeLink
        ? parseFloat(obj.activeLink.dataset.level)
        : 1;

      /* Force menu level to 2 for the link on level 1 that are active.
       * That way the children are accessible more easily
       */
      if (obj.activeLink.classList.contains(config.hasChildrenCN)) {
        obj.current = 2;
      }

      return obj;
    },
    show(lvl, element) {
      if (lvl > 1) {
        // show level 2+
        // we'll find the target ul.-level2 (submenu)
        const targetSubMenu = findTarget({
          element,
          parent: config.listItemCN,
          target: config.navCN,
        });
        // show the counterpart submenu
        targetSubMenu.classList.remove(config.hideCN);
        // remove aria attribute
        targetSubMenu.removeAttribute('aria-hidden');
      } else {
        // show level 1
        config.listItem.forEach(item => {
          item.classList.remove(config.hideCN);
          item.removeAttribute('aria-hidden');
        });
      }
    },
    hide(lvl, element) {
      if (lvl > 1) {
        // hide level 2+
        config.navs.forEach(menuLevel => {
          menuLevel.classList.add(config.hideCN);
          menuLevel.setAttribute('aria-hidden', 'true');
        });
      } else {
        // get the element parent = <li> listItem
        const listItem = findParent(element, config.listItemCN);
        // get all of the same level listItem except the trigger
        const itemsToHide = config.listItem.filter(item => item !== listItem);
        // hide items
        itemsToHide.forEach(item => {
          item.classList.add(config.hideCN);
          item.setAttribute('aria-hidden', 'true');
        });
      }
    },
  };

  const handleClick = ({ e, trigger }) => {
    // handle Click on the arrow to show subMenu
    // find associated link
    // <div = trigger = ><a = realLink></a></div>
    const linkContainer = findTarget({
      element: trigger,
      parent: config.listItemCN,
      target: config.linkCN,
    });
    // prevent default in case
    e.preventDefault();
    // get level 2+ and show them
    level.show(2, linkContainer);
    // show 'back' button
    back.show();
    // add parent link to the top
    link.state('heading', linkContainer);
    // hide other menu item (menu 1)
    level.hide(1, linkContainer);
  };

  const handleReset = (e = false) => {
    if (e) {
      e.preventDefault();
    }
    // hide the 'back' button
    back.hide();
    // hide menu level 2+
    level.hide(2);
    // reset the state of the link that was put at the top of the submenu
    link.state('reset');
    // show menu level 1
    level.show(1);
  };

  const showCorrectMenu = () => {
    const lvl = level.current();
    let parent;
    let itemsToHide;
    switch (lvl.current) {
      case 1:
        handleReset();
        break;
      case 2:
      case 3:
        // parent of the current link
        parent = findParent(lvl.activeLink, config.listItemCN);
        // get all the links that we should hide
        itemsToHide = config.listItem.filter(item => item !== parent);
        // hide level 1 items
        itemsToHide.forEach(item => {
          item.classList.add(config.hideCN);
          item.setAttribute('aria-hidden', 'true');
        });
        // remove aria attribute if previously added
        parent.removeAttribute('aria-hidden');
        // show parent if previously hidden
        parent.classList.remove(config.hideCN);
        // add correct heading on link to display
        link.state('heading', parent.querySelector(`.${config.linkCN}`));
        // show 'back' button
        back.show();
        // show level 2+
        level.show(2, lvl.activeLink);
        break;

      default:
        break;
    }
  };

  const handleTab = event => {
    if (event.key === 'Tab') {
      // keep in the focus within
      config.focusHandler.maintainFocus(event);
    }
  };

  const disableTabWithin = () => {
    config.focusHandler.focusLastActiveElement();

    // stop maintaining focus
    document.removeEventListener('keydown', handleTab);
    // disable scroll freeze
    freeze.stop();
    // stop listening ESC pressing
    handleESC.stop(() => {
      // close menu
      const { checkbox } = config;
      checkbox.checked = false;
    });
  };

  const handleTabWithin = () => {
    config.focusHandler.focusFirstFocusable();
    // maintain focus on tab
    document.addEventListener('keydown', handleTab);
    // freeze scroll
    freeze.start();
    // handle ESC button
    handleESC.init(disableTabWithin);
  };

  const handleMenu = () => {
    const { checkbox } = config;

    if (checkbox.checked) {
      // accessibility
      config.target.removeAttribute('aria-hidden');
      showCorrectMenu();
      handleTabWithin();
    } else {
      // accessibility
      config.target.setAttribute('aria-hidden', 'true');
      disableTabWithin();
    }
  };

  const toggleMenu = e => {
    const { checkbox } = config;
    if (e.key === 'Enter' || e.key === ' ') {
      // prevent scrolling when 'Space' is clicked
      e.preventDefault();
      // change input check status
      checkbox.checked = !checkbox.checked;
      // handle the menu with changed check status
      handleMenu();
    }
  };

  const build = () => {
    // handle Focus handle
    config.focusHandler = new FocusHandler(config.target);

    // find sub menu trigger button ">"
    config.target
      .querySelectorAll(config.subMenuTriggerButton)
      .forEach(trigger => {
        // handle the transitions on clicking the >
        trigger.addEventListener('click', e => handleClick({ trigger, e }));
      });

    // go back to first menu on clicking the 'back' button
    config.back.addEventListener('click', e => handleReset(e));

    // listen to menu opening/closing
    config.checkbox.addEventListener('change', () => handleMenu());

    // hamburger and dismiss are styled <label> and don't allow keyboard triggering
    // we're adding a listener on keydown to help triggering
    [config.dismiss, config.hamburger].forEach(button => {
      button.addEventListener('keydown', e => toggleMenu(e));
    });
  };

  const handleResize = () => {
    if (viewport.breakpoint.indexOf('menu-mobile') > -1) {
      // window will show mobile menu
      // accessibility
      config.menuDesktop.setAttribute('aria-hidden', true);
    } else {
      // window will show desktop menu
      const { checkbox } = config;
      // close menu on resize if it was open
      if (checkbox.checked) {
        // change input check status to false == close menu
        checkbox.checked = !checkbox.checked;
        // handle the menu with changed check status
        handleMenu();
      }

      // accessibility
      config.menuDesktop.removeAttribute('aria-hidden');
    }
  };

  const init = () => {
    if (config.target) {
      build();

      handleResize();
      // Event subscription
      events.on('breakpoint', () => {
        handleResize();
      });
    }
  };

  return {
    init,
  };
})();

export default menu.init();
