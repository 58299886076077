import escapeRegExp from './escape-regex';
// get lexicon from DOM?
const translationGuard = str => {
  // First add a *lowercase* word you *don't want to translate* to the lexicon
  // if you add some words here, you'll have to do the same in the array within the PHP function
  const lexicon = ['öbo', 'västerporten', 'rosta'];
  // Let's create variants of that word: uppercase, first letter uppercase
  lexicon.forEach(word => {
    // Set Uppercase
    lexicon.push(word.toUpperCase());
    // Capitilize first letter
    lexicon.push(word.charAt(0).toUpperCase() + word.slice(1));
  });

  // Create replace expressions for each words
  let newStr = str;
  lexicon.forEach(word => {
    // Adding <span class="notranslate"></span> will prevent google from translating the word
    newStr = newStr.replace(
      new RegExp(escapeRegExp(word), 'g'),
      `<span class="notranslate">${word}</span>`,
    );
  });

  return newStr;
};

export default translationGuard;
