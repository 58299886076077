export default (() => {
  const freezeCN = 'freeze';
  const html = document.querySelector('html');

  const start = () => {
    html.classList.add(freezeCN);
  };
  const stop = () => {
    html.classList.remove(freezeCN);
  };

  return {
    start,
    stop,
  };
})();
