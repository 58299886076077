const input = (() => {
  const config = {
    triggers: [...document.querySelectorAll('.js-inputTrigger')],
    notEmptyCN: '-not-empty',
  };

  const handleLabel = ({ trigger: t, e }) => {
    const l = t.value.length;

    switch (e.type) {
      case 'blur':
        if (l < 1) {
          t.classList.remove(config.notEmptyCN);
        }
        break;
      case 'focus':
        if (l < 1) {
          t.classList.add(config.notEmptyCN);
        }
        break;
      default:
        if (l > 0) {
          t.classList.add(config.notEmptyCN);
        }
        break;
    }
  };

  const handleFocus = ({ trigger, e }) => {
    handleLabel({ trigger, e });
  };

  const handleBlur = ({ trigger, e }) => {
    handleLabel({ trigger, e });
  };

  const build = () => {
    config.triggers.forEach(trigger => {
      // handle Label when input text is shown on start
      handleLabel({ trigger, e: false });

      trigger.addEventListener('focus', e => handleFocus({ trigger, e }));
      trigger.addEventListener('blur', e => handleBlur({ trigger, e }));
    });
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

export default input.init();

