import Flickity from 'flickity';

const carousel = (() => {
  const config = {
    triggers: [...document.querySelectorAll('.js-galleryCarousel')],
    gallery: '.js-galleryCarousel',
    controls: '.js-carouselControls',
    previous: '.-previous',
    next: '.-next',
  };

  const handleGallery = trigger => {
    const gallery = new Flickity(trigger, {
      resize: true,
      pageDots: false,
      prevNextButtons: false,
      lazyLoad: 2,
    });

    // previous
    trigger.parentNode
      .querySelector(config.previous)
      .addEventListener('click', () => {
        gallery.previous(true);
      });

    // next
    trigger.parentNode
      .querySelector(config.next)
      .addEventListener('click', () => {
        gallery.next(true);
      });
  };

  const setup = () => {
    if (config.triggers.length > 0) {
      config.triggers.forEach(trigger => handleGallery(trigger));
    }
  };

  const init = () => {
    setup();
  };

  return {
    init,
  };
})();

export default carousel.init();
