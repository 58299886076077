import { translationGuard, dataExist } from '../utils';
import API from '../api/form-contact';

const formContact = (() => {
  const config = {
    triggers: [...document.querySelectorAll('.js-formContactTrigger')],
    triggerCN: 'js-formContactTrigger',
    submit: '.js-formContactSubmit',
    successDiv: '.js-formContactSuccess',
    input: '.js-inputTrigger',
    helper: '.js-inputHelper',
    parentCN: 'js-inputParent',
    invalidCN: '-invalid',
  };

  const updateSubmitBtn = ({ trigger: t, status = 'processing' }) => {
    const submitBtn = t.querySelector(config.submit);

    if (submitBtn) {
      let txt;
      submitBtn.classList.remove('-progress');

      switch (status) {
        case 'initial':
          submitBtn.removeAttribute('disabled');
          txt = submitBtn.dataset.initial;
          break;
        case 'error':
          submitBtn.removeAttribute('disabled');
          txt = submitBtn.dataset.error;
          break;
        case 'done':
          txt = submitBtn.dataset.initial;
          break;
        default:
          submitBtn.setAttribute('disabled', true);
          submitBtn.classList.add('-progress');
          txt = submitBtn.dataset.processing;
          break;
      }

      submitBtn.textContent = txt;
    }
  };

  const errors = {
    reset(t) {
      // get all the inputs with errors
      const inputWithErrors = [
        ...t.querySelectorAll(`${config.input}.${config.invalidCN}`),
      ];

      inputWithErrors.forEach(input => {
        const helper = input.parentNode.querySelector(config.helper);
        // remove error class
        input.classList.remove(config.invalidCN);
        // remove text
        if (helper) {
          helper.innerHTML = '';
        }
      });
    },
    handle({ trigger: t, arr }) {
      arr.forEach(err => {
        const input = t.querySelector(`[name='${err.field}']`);
        const helper = input.parentNode.querySelector(config.helper);
        // change input state
        input.classList.add(config.invalidCN);
        // add text
        if (helper) {
          helper.innerHTML = translationGuard(err.message);
        }
      });
    },
  };

  const successDiv = {
    show({ trigger, message }) {
      const s = trigger.querySelector(config.successDiv);
      s.classList.add('-show');
      s.setAttribute('aria-hidden', 'false');
      s.innerHTML = `<h3 class="title -h3">${translationGuard(
        message.title,
      )}</h3><p>${translationGuard(message.body)}</p>`;
    },
    hide({ trigger }) {
      const s = trigger.querySelector(config.successDiv);
      s.classList.remove('-show');
    },
  };

  const handleSuccess = ({ trigger, message }) => {
    successDiv.show({ trigger, message });
    updateSubmitBtn({ trigger, status: 'done' });
  };

  const handleSubmit = ({ trigger, e }) => {
    const formData = new FormData(trigger);
    // prevent action
    e.preventDefault();
    // deal with Submit button
    updateSubmitBtn({ trigger });

    // reset all error messages from the form
    errors.reset(trigger);

    API.post('/', formData)
      .then(response => {
        // response
        if (dataExist(response, 'data')) {
          if (dataExist(response.data, 'message')) {
            handleSuccess({ trigger, message: response.data.message });
          }
        }
      })
      .catch(error => {
        // show correct message on submit button
        updateSubmitBtn({ trigger, status: 'error' });

        // show error message
        if (dataExist(error, 'response')) {
          if (dataExist(error.response, 'data')) {
            errors.handle({ trigger, arr: error.response.data.errors });
          }
        }
      });
  };

  const build = () => {
    config.triggers.forEach(trigger => {
      trigger.addEventListener('submit', e => handleSubmit({ trigger, e }));
    });
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

export default formContact.init();
